import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'
import styled from 'styled-components'
import { Container } from '../elements'

// const customHandleClick = async () => {
//   // (window.location = 'mailto:info@absolutecode.nl')
//   const response = await fetch('/.netlify/functions/strava-fetch')
//   const message = await response.json()

//   return console.log(message.msg)
// }

const HeroStyles = styled.section`
  --hero-background-color: hsl(218, 72%, 49%);
  --hero-button-background-color: hsla(0, 0%, 0%, 0);
  --hero-button-hover-background-color: var(--color-white);
  --hero-button-color: hsl(0, 0%, 100%);
  --hero-button-hover-color: var(--hero-background-color);

  @media (prefers-color-scheme: dark) {
    --hero-background-color: hsl(219, 74%, 45%);
    --hero-button-background-color: hsla(0, 0%, 0%, 0);
    --hero-button-hover-background-color: var(--color-white);
    --hero-button-color: hsl(0, 0%, 100%);
    --hero-button-hover-color: var(--hero-background-color);
  }
`

const Title = styled.h1`
  font-weight: 700;
  font-size: 1.875rem;
  /* max-width: 28rem; */
  margin-bottom: 1rem;
  line-height: 1.1;
  letter-spacing: -0.025em;
  margin-left: auto;
  margin-right: auto;

  @media (min-width: 375px) {
    font-size: 2.25rem;
  }

  @media (min-width: 768px) {
    max-width: 100%;
  }

  @media (min-width: 1024px) {
    font-size: 3rem;
    margin-bottom: 1.5rem;
  }

  @media (min-width: 1280px) {
    font-size: 4rem;
  }
`

const Subtitle = styled.p`
  font-weight: 400;
  font-size: 1.25rem;
  /* max-width: 22rem; */
  margin-bottom: 1rem;
  line-height: 1.375;
  margin-left: auto;
  margin-right: auto;

  @media (min-width: 375px) {
    font-size: 1.25rem;
  }
  @media (min-width: 768px) {
    max-width: 100%;
    font-size: 1.25rem;
  }

  @media (min-width: 1024px) {
    font-size: 1.5rem;
    margin-bottom: 1.5rem;
  }

  @media (min-width: 1280px) {
    /* max-width: 36rem; */
  }
`

const Hero = ({ title, subtitle }) => (
  <StaticQuery
    query={graphql`
      query {
        heroImage: file(relativePath: { eq: "portrait.png" }) {
          childImageSharp {
            fluid(maxWidth: 1370) {
              ...GatsbyImageSharpFluid_tracedSVG
            }
          }
        }
      }
    `}
    render={data => (
      <HeroStyles
        style={{
          background: 'var(--hero-background-color)',
          color: 'var(--color-white)',
          paddingTop: '5rem',
        }}
        className="overflow-hidden"
      >
        <Container>
          <div
            css={`
              padding-top: 3rem;
              @media screen and (min-width: 550px) {
                padding-top: 5rem;
              }
              @media screen and (min-width: 768px) {
                padding-top: 2rem;
              }
            `}
            className="flex-initial md:flex"
          >
            {/* <div className="text-center mx-auto max-w-md md:text-left sm:max-w-xl md:max-w-full md:flex-1 md:pt-16 lg:pt-24 xl:pt-40 md:-mr-2 xl:-mr-20"> */}
            <div className="text-center mx-auto md:text-left md:flex-1 md:pt-16 lg:pt-24 xl:pt-40 md:-mr-2 xl:-mr-20">
              <Title>{title}</Title>
              <Subtitle>{subtitle}</Subtitle>
              <a
                href="#latest"
                className="w-auto border font-semibold text-md md:text-lg lg:text-xl py-3 px-4 rounded leading-tight"
                css={`
                  transition: 300ms all cubic-bezier(0.4, 0, 0.6, 1);
                  background-color: var(--hero-button-background-color);
                  color: var(--hero-button-color);
                  display: inline-flex;
                  align-items: baseline;
                  margin-bottom: 5rem;
                  :hover {
                    background-color: var(--hero-button-hover-background-color);
                    color: var(--hero-button-hover-color);
                  }
                `}
                // onClick={customHandleClick}
              >
                Bekijk werk
                <svg
                  css={`
                    margin-left: 0.3em;
                    fill: currentColor;
                  `}
                  xmlns="//www.w3.org/2000/svg"
                  width="0.5em"
                  height="0.6em"
                  viewBox="0 0 16 28"
                >
                  <defs></defs>
                  <path d="M14.639,15.929L5.248,26.335a2.646,2.646,0,1,1-3.741-3.741l7.74-8.577L1.479,5.409A2.646,2.646,0,0,1,5.22,1.668l9.391,10.406A2.8,2.8,0,0,1,14.639,15.929Z"></path>
                </svg>
              </a>
            </div>
            <div className="self-end max-w-xs mx-auto pt-4 sm:pt-0 sm:flex-1 md:max-w-lg md:-mr-12 md:max-w-full xl:m-mr-12 ">
              <Img fluid={data.heroImage.childImageSharp.fluid} />
            </div>
          </div>
        </Container>
      </HeroStyles>
    )}
  />
)

export default Hero
