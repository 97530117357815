import React from 'react'

const Gatsby = () => (
  <svg viewBox="0 0 107 28" version="1.1">
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="gatsby-horizontal">
        <g
          id="Group"
          transform="translate(38.000000, 5.000000)"
          fill="currentColor"
        >
          <path
            d="M24.9,7 L27.7,7 L27.7,17 L24.9,17 L24.9,15.7 C23.9,17.2 22.6,17.3 21.8,17.3 C18.7,17.3 16.7,14.9 16.7,12 C16.7,9 18.7,6.7 21.6,6.7 C22.4,6.7 23.9,6.8 24.8,8.3 L24.8,7 L24.9,7 Z M19.7,12 C19.7,13.6 20.8,14.8 22.5,14.8 C24.1,14.8 25.3,13.6 25.3,12 C25.3,10.4 24.2,9.2 22.5,9.2 C20.9,9.2 19.7,10.4 19.7,12 Z"
            id="Shape"
            fillRule="nonzero"
          />
          <polygon
            id="Path"
            points="33.2 9.4 33.2 17 30.4 17 30.4 9.4 29.3 9.4 29.3 7 30.4 7 30.4 3.6 33.2 3.6 33.2 7 35.1 7 35.1 9.4"
          />
          <path
            d="M41.7,9.4 C41,8.8 40.4,8.7 40.1,8.7 C39.4,8.7 39,9 39,9.5 C39,9.8 39.1,10.1 39.9,10.4 L40.6,10.6 C41.4,10.9 42.6,11.2 43.1,12 C43.4,12.4 43.6,13 43.6,13.7 C43.6,14.6 43.3,15.5 42.5,16.2 C41.7,16.9 40.7,17.3 39.5,17.3 C37.4,17.3 36.3,16.3 35.6,15.6 L37.1,13.9 C37.7,14.5 38.5,15.1 39.3,15.1 C40.1,15.1 40.7,14.7 40.7,14 C40.7,13.4 40.2,13.1 39.8,13 L39.2,12.8 C38.5,12.5 37.7,12.2 37.1,11.6 C36.6,11.1 36.3,10.5 36.3,9.7 C36.3,8.7 36.8,7.9 37.3,7.4 C38.1,6.8 39.1,6.7 39.9,6.7 C40.6,6.7 41.8,6.8 43.1,7.8 L41.7,9.4 Z"
            id="Path"
          />
          <path
            d="M47.8,8.3 C48.8,6.9 50.2,6.7 51,6.7 C53.9,6.7 55.9,9 55.9,12 C55.9,15 53.9,17.3 50.9,17.3 C50.3,17.3 48.8,17.2 47.7,15.7 L47.7,17 L45,17 L45,0.2 L47.8,0.2 L47.8,8.3 Z M47.5,12 C47.5,13.6 48.6,14.8 50.3,14.8 C51.9,14.8 53.1,13.6 53.1,12 C53.1,10.4 52,9.2 50.3,9.2 C48.6,9.2 47.5,10.4 47.5,12 Z"
            id="Shape"
            fillRule="nonzero"
          />
          <polygon
            id="Path"
            points="60.5 15.5 55.7 7 59 7 62.1 12.7 64.9 7 68.1 7 60.1 22.3 56.9 22.3"
          />
          <path
            d="M16,8.7 L13.2,8.7 C13.2,8.7 9,8.7 9,8.7 L9,11.5 L12.7,11.5 C12.1,13.4 10.7,14.7 8.1,14.7 C5.2,14.7 3.1,12.3 3.1,9.4 C3.1,6.5 5.1,4 8,4 C9.6,4 11.2,4.8 12.2,6.1 L14.5,4.6 C13,2.5 10.6,1.3 8,1.3 C3.6,1.3 0,4.9 0,9.4 C0,13.9 3.4,17.5 8,17.5 C12.6,17.5 16,13.9 16,9.4 C16.1,9.1 16,8.9 16,8.7 Z"
            id="Path"
          />
        </g>
        <g id="Group">
          <g transform="translate(3.000000, 3.000000)" fill="#FFFFFF" id="Path">
            <path d="M22,11 L15,11 L15,13 L19.8,13 C19.1,16 16.9,18.5 14,19.5 L2.5,8 C3.7,4.5 7.1,2 11,2 C14,2 16.7,3.5 18.4,5.8 L19.9,4.5 C17.9,1.8 14.7,0 11,0 C5.8,0 1.4,3.7 0.3,8.6 L13.5,21.8 C18.3,20.6 22,16.2 22,11 Z" />
            <path d="M0,11.1 C0,13.9 1.1,16.6 3.2,18.7 C5.3,20.8 8.1,21.9 10.8,21.9 L0,11.1 Z" />
          </g>
          <path
            d="M14,0 C6.3,0 0,6.3 0,14 C0,21.7 6.3,28 14,28 C21.7,28 28,21.7 28,14 C28,6.3 21.7,0 14,0 Z M6.2,21.8 C4.1,19.7 3,16.9 3,14.2 L13.9,25 C11.1,24.9 8.3,23.9 6.2,21.8 Z M16.4,24.7 L3.3,11.6 C4.4,6.7 8.8,3 14,3 C17.7,3 20.9,4.8 22.9,7.5 L21.4,8.8 C19.7,6.5 17,5 14,5 C10.1,5 6.8,7.5 5.5,11 L17,22.5 C19.9,21.5 22.1,19 22.8,16 L18,16 L18,14 L25,14 C25,19.2 21.3,23.6 16.4,24.7 Z"
            id="Shape"
            fill="#663399"
            fillRule="nonzero"
          />
        </g>
      </g>
    </g>
  </svg>
)

export default Gatsby
