import React, { useContext } from 'react'
import styled from 'styled-components'
import Fade from 'react-reveal/Fade'
import { SecondaryButton } from '../elements/buttons'
import Img from 'gatsby-image'
import { Gatsby, Contentful, Netlify } from '../icons'
import { FiArrowUpRight } from 'react-icons/fi'
import { Container } from '../elements'

// import InterfaceContext from '../../context/InterfaceContext'

const StyledHeading = styled.div.attrs({
  className: 'max-w-6xl mx-auto px-8',
})`
  /* text-align: center;
  padding-top: 6rem;
  padding-bottom: 2rem; */
  /* max-width: 70%; */

  span {
    margin: 0;
    /* opacity: 0.6; */
    line-height: 1.3;
    /* margin-bottom: 10px; */
    font-weight: 400;
    font-size: 1.25rem;
    display: block;
    color: #999;
  }

  h1 {
    margin-top: 0;
    margin-bottom: 20px;
    line-height: 1.05;
    font-size: 3.75rem;
    font-weight: 700;
    /* font-family: 'Freight Sans'; */
    /* color: #111; */
  }
`

const StyledLatest = styled.section`
  padding: 2rem 0 6rem 0;

  .banner {
    background-color: #f5f5fa;
    border-radius: 4px;
    /* margin-bottom: 2rem; */
  }
  .banner-inner {
    display: flex;
    flex-wrap: wrap;
    padding: 4rem 3rem 1.5rem 3rem;

    @media screen and (max-width: 1024px) {
      flex-wrap: wrap;
      justify-content: center;
      padding: 3rem 2rem 1rem 2rem;
    }

    &:hover {
      .icons {
        /* filter: grayscale(0); */
        /* opacity: 1; */
      }
    }

    .image-wrapper {
      flex: 0 0 50%;
      max-width: 50%;
      padding-right: 1rem;
      padding-left: 1rem;

      @media screen and (max-width: 768px) {
        flex: 0 0 100%;
        max-width: 100%;
      }

      .banner-img {
        border: 1px solid rgba(0, 0, 0, 0.06);
        border-radius: 4px;
        /* box-shadow: 0 0 26px 0 rgba(15, 22, 33, 0.1); */
        max-width: 100%;
        height: auto;
        transition: 0.3s ease-in-out;
        overflow: hidden;
        /* filter: grayscale(1); */
        &:hover {
          transform: translateY(-5px);
          /* filter: grayscale(0); */
          /* box-shadow: 0 0 1px 0 rgba(0, 0, 0, 0.05),
            0 4px 12px rgba(0, 0, 0, 0.05), 0 15px 30px rgba(0, 0, 0, 0.1); */
          box-shadow: 0 0 1px rgba(0, 0, 0, 0.1), 0 15px 30px rgba(0, 0, 0, 0.1);
        }
      }
    }

    .text {
      flex: 0 0 50%;
      max-width: 50%;
      padding-right: 1rem;
      padding-left: 1rem;
      position: relative;

      @media screen and (max-width: 768px) {
        margin-top: 3rem;
        text-align: center;
        flex: 0 0 100%;
        max-width: 80%;
      }

      @media screen and (max-width: 768px) {
        max-width: 90%;
      }

      @media screen and (max-width: 550px) {
        max-width: 100%;
      }

      h4 {
        margin-top: 0;
        margin-bottom: 0.75rem;
        font-size: 1.875rem;
        /* font-family: 'Freight Sans'; */
        color: #111;
        font-weight: 800;
      }

      p {
        line-height: 1.5;
        font-size: 1.125rem;
        font-weight: 400;
        margin: 0;
        margin-bottom: 1.5rem;
        color: #333;
      }
      ul {
        list-style-position: inside;
        margin: 1rem 0;
        li {
          margin: 0.5rem 0;
        }
      }
      a {
        display: inline-flex;
        text-decoration: none;
      }
    }

    .icons {
      display: grid;
      grid-template-columns: 1fr auto 1fr;
      justify-items: center;
      width: 100%;
      padding: 1rem 0;
      /* filter: grayscale(1); */
      /* transition: 0.3s ease-in-out; */
      /* opacity: 0.5; */
      svg {
        height: 2.5rem;
        @media screen and (max-width: 768px) {
          height: 1.8rem;
        }

        @media screen and (max-width: 550px) {
          height: 1.4rem;
        }
      }
    }

    .text-center {
      text-align: center;
      margin-top: 4rem;
    }

    .subtitle {
      width: 100%;
      margin-bottom: 0;
      text-transform: uppercase;
      margin-bottom: 0.5rem;
      color: #999;
      /* opacity: 0.4; */
      font-size: 0.75rem;
      letter-spacing: 0.05em;
      position: relative;
      /* font-family: 'Poppins Medium'; */
      display: block;
      font-weight: 600;

      span {
        display: inline-block;
      }
      span:before,
      span:after {
        border-top: 1px solid rgba(0, 0, 0, 0.2);
        display: block;
        height: 1px;
        content: ' ';
        width: 38%;
        position: absolute;
        left: 0;
        top: 0.4rem;
      }
      span:after {
        right: 0;
        left: auto;
      }
    }
  }
`

const AnimatedImages = styled.div`
  position: relative;
  width: 70rem;
  max-width: 100%;
  margin: 0 auto;

  @media screen and (min-width: 768px) {
    margin-right: -25rem;
    max-width: 80vw;
  }

  @keyframes cross-fade {
    0% {
      opacity: 1;
    }

    45% {
      opacity: 1;
    }

    55% {
      opacity: 0;
    }

    100% {
      opacity: 0;
    }
  }

  div {
    animation-name: cross-fade;
    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite;
    animation-duration: 8s;
    animation-direction: alternate-reverse;
  }

  div:nth-child(2) {
    position: absolute !important;
    left: 0;
    top: 0;
    width: 100%;
    animation-direction: alternate;
  }
`

const Latest = ({
  category,
  title,
  description,
  img,
  imgLight,
  imgDark,
  url,
}) => {
  // const { isDarkMode } = useContext(InterfaceContext)
  return (
    <section
      id="latest"
      css={`
        background-color: var(--background-color-primary);
      `}
      className="py-24 md:py-32 xl:py-40 overflow-hidden"
    >
      <Container>
        <div className="block md:flex mx-auto items-center">
          <div className="text-center mx-auto max-w-md md:text-left md:flex-1 ">
            <h2
              className="whitespace-no-wrap font-bold mb-4 text-3xl sm:text-4xl lg:text-5xl tracking-tight leading-none"
              css={`
                color: var(--color-primary);
              `}
            >
              Laatste project
            </h2>
            <p
              css={`
                color: var(--color-secondary);
              `}
              className="text-base mx-auto max-w-xs sm:max-w-md sm:text-lg xl:text-xl mb-4"
            >
              Portfolio website gemaakt voor fotograaf om zijn beste werk te
              presenteren aan bestaande en toekomstige opdrachtgevers. Content
              staat centraal met een eenvoudige en duidelijke navigatie.
            </p>
            <a
              href={url}
              target="_blank"
              rel="noopener noreferrer"
              className="inline-flex text-base sm:text-lg xl:text-xl items-center hover:underline mb-10"
              css={`
                color: var(--link-color);
              `}
            >
              Ga naar website
              <FiArrowUpRight />
            </a>
          </div>
          <div className="flex-1">
            <AnimatedImages>
              <Img fluid={imgLight} />
              <Img fluid={imgDark} />
            </AnimatedImages>
          </div>
        </div>
        <div
          css={`
            display: grid;
            grid-template-columns: 1fr auto 1fr;
            justify-items: center;
            width: 100%;
            padding-top: 3rem;
            color: var(--color-tertiary);
            transition: 0.3s ease-in-out;

            svg {
              height: 2.5rem;
              @media screen and (max-width: 768px) {
                height: 1.8rem;
              }

              @media screen and (max-width: 640px) {
                height: 1.2rem;
              }
            }
          `}
        >
          <Contentful />
          <Gatsby />
          <Netlify />
        </div>
        {/* <div className="max-w-5xl mx-auto px-4 md:px-8 xl:px-16">
        <Fade bottom distance="6rem">
          <StyledLatest>
            <div className="banner">
              <div className="banner-inner">
                <div className="image-wrapper">
                  <a href={url}>
                    <Img className="banner-img" fluid={img} />
                  </a>
                </div>
                <div className="text">
                  <span className="subtitle">{category}</span>
                  <h4>{title}</h4>
                  <p>{description}</p>
                  <a href={url} target="_blank" rel="noopener noreferrer">
                    <SecondaryButton>
                      Bekijk
                      <FiArrowUpRight />
                    </SecondaryButton>
                  </a>
                </div>
                <p className="subtitle text-center">
                  <span>Powered by</span>
                </p>
                <div className="icons">
                  <Contentful />
                  <Gatsby />
                  <Netlify />
                </div>
              </div>
            </div>
          </StyledLatest>
        </Fade>
      </div> */}
      </Container>
    </section>
  )
}

export default Latest
