import styled from 'styled-components'

const BaseButton = styled.button`
  display: inline-flex;
  justify-content: center;
  color: white;
  font-family: 'Proxima Nova';
  border: none;
  border-radius: 4px;
  transition: 0.24s;
  font-weight: 600;
  text-align: center;
  align-items: center;
  cursor: pointer;
  line-height: 1;

  &:focus {
    outline: 0;
  }
`
export const PrimaryButton = styled(BaseButton)`
  background-color: hsl(118, 80%, 44%);
  font-size: 1.25rem;
  padding: 0.75rem 2.375rem;

  &:hover {
    background-color: hsl(118, 80%, 40%);
  }
`
export const SecondaryButton = styled(BaseButton)`
  background-color: hsl(211, 100%, 50%);
  font-size: 1.25rem;
  padding: 0.75rem 0.875rem;
  &:hover {
    background-color: hsl(211, 100%, 46%);
  }
  svg {
    /* height: 10px;
    width: 10px; */
    margin-left: 0.5em;
  }
`
