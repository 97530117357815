import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/layout'
import SEO from '../components/seo'
import Hero from '../components/hero'
import Latest from '../components/latest'
import CallToAction from '../components/call-to-action'

const IndexPage = ({ data }) => (
  <Layout isInverted>
    <SEO
      title="Home"
      keywords={[
        `gatsby`,
        `react`,
        `absolute code`,
        `webontwikkeling`,
        `Remco Zwartepoorte`,
      ]}
    />
    <Hero
      title="Webontwikkeling met gevoel voor design"
      // title="Webontwikkeling met oog voor design"
      // text="Vanuit Amersfoort ontwerp en bouw ik op maat gemaakte online toepassingen."
      subtitle="Vooruitstrevend in het bouwen van interactieve gebruikersinterfaces met HTML, CSS en JavaScript."
    />

    <Latest
      category="Design & development"
      title="Paul Rietveld Photography"
      description="Portfolio website gemaakt voor fotograaf om zijn beste werk te
                presenteren aan bestaande en toekomstige opdrachtgevers. Content
                staat centraal met een eenvoudige en duidelijke navigatie."
      img={data.latestImage.childImageSharp.fluid}
      imgLight={data.screenshotLight.childImageSharp.fluid}
      imgDark={data.screenshotDark.childImageSharp.fluid}
      url="https://paulrietveld.nl"
    />
    <CallToAction></CallToAction>
  </Layout>
)

export default IndexPage

export const query = graphql`
  query {
    screenshotLight: file(relativePath: { eq: "paulrietveld-light.png" }) {
      childImageSharp {
        fluid(maxWidth: 896) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    screenshotDark: file(relativePath: { eq: "paulrietveld-dark.png" }) {
      childImageSharp {
        fluid(maxWidth: 896) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    latestImage: file(relativePath: { eq: "screenshot.png" }) {
      childImageSharp {
        fluid(maxWidth: 1100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
